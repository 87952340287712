<template>
  <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
    <h5 class="card-title">
      <slot></slot>
    </h5>
<!--    <h5 v-if="isEditable" class="card-title">{{title}}</h5>-->
<!--    <h5 v-if="!isEditable" class="card-title">{{title}} Updation</h5>-->
    <div class="header-elements">
      <div class="list-icons">
        <a class="list-icons-item" data-action="reload" @click="loadDataEvent" ></a>
        <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: 'Window Title',
    },
    isEditable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  name: 'WindowTitleBar',
  methods:{
    loadDataEvent() {
      // this.$emit('loadData');
    },
    closeThis() {
      this.$emit('close_window');
    },
  }
}
</script>

<style scoped>

</style>
